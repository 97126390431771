import React from "react"
import Image from "../assets/images/time-calendar.svg"
import Mindefulness from "../assets/images/mindfulness.svg"
import SavingsImage from "../assets/images/savings.svg"
import Layout from "../components/Layout/Layout"
import RegistrationCardForm from "../components/RegistrationCardForm"


const IndexPage = () => {
  return(
    <Layout>
      <header className="header"
              style={{ backgroundImage: "linear-gradient(135deg, #f7fcfb 0%, #fff 50%, #effcfa 100%)" }}>
        <div className="container">
          <div className="row align-items-center">

            <div className="col-lg-6">
              <h1>Ontvang eenvoudig <u>compensaties</u> voor <u>treinvertraging</u></h1>
              <p className="lead mt-5 mb-8">Via weertelaat.be ontvang je eenvoudig <strong>financiële
                compensaties</strong> voor <strong>trein vertragingen</strong> langer dan 15 minuten. Laat ons
                weten tussen welke stations jij pendelt, wij doen de rest! </p>
              <p className="gap-xy">
                <a className="btn btn-lg btn-round btn-primary" href="#">Hoe werkt het?</a>
                <a className="btn btn-lg btn-round btn-outline-secondary" href="#">Inschrijven</a>
              </p>
            </div>

            <div className="col-lg-5 ml-auto d-none d-lg-block">
              <img src={Image} alt="img"/>
            </div>

          </div>
        </div>
      </header>


      <section className="section overflow-hidden">
        <div className="container">
          <header className="section-header">
            <h2>Treinreizigers met minstens 15 minuten vertraging hebben recht op compensatie</h2>
            <hr/>
            <p className="lead">
              Veel pendelaars weten niet dat er een compensatieregeling bestaat voor trein vertragingen. In principe
              kan <strong>elke reiziger met een vertraging van 15 minuten of meer een compensatie aanvraag
              indienen</strong> bij de Belgische
              spoorwegen. Vertragingen van 15 minuten tot 59 minuten moeten herhaaldelijk zijn voorgekomen op hetzelfde
              traject binnen een periode van 6 maanden.
            </p>
          </header>


          <div className="row gap-y text-center">

            <div className="col-md-4 d-flex flex-column">
              <div className="mb-7">
                <p className="text-info lead-7 mb-0">+15 min.</p>
                <p className="lead-3">Terugbetaling van 25%</p>
                <p className="">Min. 20 vertragingen op 6 maanden</p>
              </div>
            </div>

            <div className="col-md-4 d-flex flex-column">
              <div className="mb-7">
                <span className="text-info lead-7">+30 min.</span><br/>
                <p className="lead-3">Terugbetaling van 50%</p>
                <p className="">Min. 10 vertragingen op 6 maanden</p>
              </div>
            </div>

            <div className="col-md-4 d-flex flex-column">
              <div className="mb-7">
                <span className="text-info lead-7">+60 min.</span><br/>
                <p className="lead-3">Volledige terugbetaling</p>
                <p className="">Elke vertraging geeft recht op compensatie</p>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="section text-center bg-light">
        <div className="container">

          <header className="section-header">
            <h2>
              Probeer het volledig vrijblijvend uit
            </h2>
            <hr/>
            <p className="lead">Weertelaat.be is binnenkort beschikbaar voor alle geregistreerde
              gebruikers. <strong>Weertelaat.be verzamelt  automatisch alle vertragingen tussen stations van mensen die zich
                hebben ingeschreven.</strong> Registreer je dus vandaag nog om ervoor te zorgen dat de vertragingen die je oploopt kunnen
              worden gebruikt voor jouw compensatie aanvraag bij de NMBS.</p>
          </header>
          <RegistrationCardForm />

        </div>
      </section>

    </Layout>
  )
}

export default IndexPage
